import React, { memo } from 'react';
import logoImg from '../../../Constants/Resources/Images/panopticalogogreyscale.png';
import './styles.scss';
/**
 * A simple component for the spinning Panoptica logo.
 */
export var InitialLoadingIndicator = function () { return (
// #region RENDER
React.createElement("div", { className: "InitialLoadingIndicator lds-circle" },
    React.createElement("img", { src: logoImg, className: "InitialLogo", alt: "Panoptica Logo", "data-testid": "initialLogo" }))
// #endregion
); };
export default memo(InitialLoadingIndicator);
